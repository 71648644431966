// Libraries
import React, { Fragment } from 'react'
import { BarsOutlined, FolderOutlined } from '@ant-design/icons'
import { Card } from 'antd'
import { Button } from 'reactstrap'

// Components
import AdditionalPropertyData from './AdditionalPropertyData'
import ModalCreateLoanFileContainer from './ModalCreateLoanFileContainer'
import ModalEditLoanFileVAFields from './ModalEditLoanFileVAFields'
import ModalEncompassLoanSettings from './EncompassLoan/ModalEncompassLoanSettings'
// Common
import ModalTrigger from '../../common/modals/ModalTrigger'
import { checkTableData, checkPermission } from '../../common/utils/helpers'
import { paymentOptionsToReadable } from '../../common/constants/borrowerPaymentOptions'


export default function OrderDetailsTab(props) {
  const { loanData, is_amc_lender, hasOrder } = props
  const encompass_loan_replication = checkPermission('encompass_loan_replication')
  const encompass_manual_loan_push = checkPermission('encompass_manual_loan_push')
  const encompass_loan_transfer = checkPermission('encompass_loan_transfer')
  return (
    <Fragment>
      <div align="right">
        {
          checkPermission('loan_edit') && hasOrder && !is_amc_lender &&
          <ModalTrigger
            component={ModalCreateLoanFileContainer}
            modalClassName='modal-primary'
            loanToEdit={loanData}
            loanId={loanData.id}
            refreshOrder={props.refreshOrder}
            order_due_date={props.order_due_date}
            consumer_payment_style={props.consumer_payment_style}
          >
            <Button className="btn btn-primary mr-2">Edit Loan File</Button>
          </ModalTrigger>
        }
        {
          checkPermission('loan_edit') && loanData.is_va_loan && !is_amc_lender &&
          <ModalTrigger
            component={ModalEditLoanFileVAFields}
            modalClassName='modal-primary'
            loanToEdit={loanData}
            id={loanData.id}
            loanData={loanData}
          >
            <Button className="btn btn-primary mr-2">Edit VA Fields</Button>
          </ModalTrigger>
        }
        {
          // `is_encompass_lender` only applies to SDK lenders, `ellie_loan_id` only applies to API lenders.
          (encompass_loan_replication || encompass_manual_loan_push || encompass_loan_transfer) &&
          (loanData.is_encompass_lender || loanData.ellie_loan_id) &&
          <ModalTrigger
            component={ModalEncompassLoanSettings}
            modalClassName='modal-primary'
            hasOrder={hasOrder}
            loanData={loanData}
            refreshOrder={props.refreshOrder}
            permissions={{
              encompass_loan_replication: encompass_loan_replication,
              encompass_manual_loan_push: encompass_manual_loan_push,
              encompass_loan_transfer: encompass_loan_transfer,
            }}
          >
            <Button className="btn btn-primary mr-2">Encompass Settings</Button>
          </ModalTrigger>
        }
      </div>
      <div className='row order-details-container'>
        <div className='order-timeline col-sm-12 col-md-6 mt-3'>
          <h4 className='lead'><FolderOutlined /> Loan Details</h4>
          <Card className='border-0'>
            {loanData.appraisal_type &&
                    <div className="user-info">
                      <div className="user-info__heading">Loan Purpose:</div>
                      <div className="user-info__texr">{loanData.appraisal_type}</div>
                    </div>}
            {!hasOrder && <div className="user-info">
              <div className="user-info__heading">Priority:</div>
              <div className="user-info__texr">
                {loanData.rush
                  ? <div className="user-info__texr">Rush</div>
                  : <div className="user-info__texr">Normal</div>}
              </div>
            </div>}
            {!hasOrder && props.should_show_consumer_payment_style_field && <div className="user-info">
              <div className="user-info__heading">Borrower Payment Option:</div>
              <div className="user-info__texr user-info-longText">
                <div className="user-info__texr">{paymentOptionsToReadable[loanData.consumer_payment_style]}</div>
              </div>
            </div>}
            {loanData.officer &&
                    <div className="user-info">
                      <div className="user-info__heading">Loan Officer:</div>
                      <div className="user-info__texr">{loanData.officer.firstname + ' ' + loanData.officer.lastname}</div>
                    </div>}
            {loanData.assigned && !loanData.has_related_order &&
                    <div className="user-info">
                      <div className="user-info__heading">Case Number:</div>
                      <div className="user-info__texr">{loanData.assigned.join(', ')}</div>
                    </div>}
            {loanData.authorization_code &&
                    <div className="user-info">
                      <div className="user-info__heading">Electronic Consent:</div>
                      <div className="user-info__texr">{loanData.authorization_code}</div>
                    </div>}
            {loanData.borrower_amount &&
                    <div className="user-info">
                      <div className="user-info__heading">Borrower Amount: </div>
                      <div className="user-info__texr">${loanData.borrower_amount.toFixed(2)}</div>
                    </div>}
            {[
              { field: 'loan_type', readable: 'Loan Type' },
              { field: 'case_number', readable: 'Case Number' },
              { field: 'purchase_price', readable: 'Purchase Price', money: true },
              { field: 'estimated_value', readable: 'Estimated Value', money: true },
            ].map(field => {
              if (loanData[field.field]) {
                let displayValue = loanData[field.field]
                if (field.money) {
                  // add some formatting
                  displayValue = '$' + parseFloat(displayValue).toFixed(2)
                }
                return (<div className='user-info' key={field.field}>
                  <div className="user-info__heading">{field.readable}:</div>
                  <div className="user-info__text">{displayValue}</div>
                </div>)
              }
            })}
            {loanData.du_case_file_id &&
              <div className="user-info">
                <div className="user-info__heading">DU Case File ID:</div>
                <div className="user-info__texr">{loanData.du_case_file_id}</div>
              </div>}
            {loanData.lp_key_number &&
              <div className="user-info">
                <div className="user-info__heading">LPA Key Number: </div>
                <div className="user-info__texr">{loanData.lp_key_number}</div>
              </div>}
            <AdditionalPropertyData {...loanData} />
          </Card>
        </div>
        <div className='order-timeline col-sm-12 col-md-6 mt-3'>
          <h4 className='lead'><BarsOutlined /> Important Dates</h4>
          <Card className='border-0'>
            {/*            {loanData.due_date &&
                    <div className="user-info">
                      <div className="user-info__heading">Due Date:</div>
                      <div className="user-info__texr">{checkTableData(loanData.due_date)}</div>
                    </div>}
*/}            {loanData.commitment_due &&
                    <div className="user-info">
                      <div className="user-info__heading">P&S Commitment Date:</div>
                      <div className="user-info__texr">{checkTableData(loanData.commitment_due)}</div>
                    </div>}
            <div className="user-info">
              <div className="user-info__heading">Date Created:</div>
              <div className="user-info__texr">{checkTableData(loanData.created)}</div>
            </div>
          </Card>
        </div>
      </div>
    </Fragment>
  )
}
