import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Tag, Badge, Popover } from 'antd'
import {
  CheckCircleFilled,
  WarningFilled,
  MinusCircleFilled,
  WarningOutlined,
  MinusOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import { REPURCHASE_WARRANTY_STATUSES, REPURCHASE_WARRANTY_INVESTOR_STATUSES } from '@app/common/constants/warranty'
import { ORDER_STATUS } from '@app/common/constants/orders'

import styles from './OrderWarrantyStatus.module.css'
import classNames from 'classnames'

export const repurchaseWarrantyStatusToTag = {
  [REPURCHASE_WARRANTY_STATUSES.DISABLED]: 'default',
  [REPURCHASE_WARRANTY_STATUSES.PENDING]: 'processing',
  [REPURCHASE_WARRANTY_STATUSES.WARRANTY_SKIPPED]: 'default',
  [REPURCHASE_WARRANTY_STATUSES.WARRANTY_DECLINED]: 'warning',
  [REPURCHASE_WARRANTY_STATUSES.WARRANTY_GRANTED]: 'success',
  default: 'default',
}

export const repurchaseWarrantyByStatus = {
  [REPURCHASE_WARRANTY_STATUSES.WARRANTY_DECLINED]: '#fa8c16',
  [REPURCHASE_WARRANTY_STATUSES.WARRANTY_GRANTED]: '#87d068',
}

const repurchaseWarrantyStatusToVisibility = {
  [REPURCHASE_WARRANTY_STATUSES.WARRANTY_DECLINED]: true,
  [REPURCHASE_WARRANTY_STATUSES.WARRANTY_GRANTED]: true,
  [REPURCHASE_WARRANTY_STATUSES.WARRANTY_SKIPPED]: true,
  default: false,
}

const orderStatusVisibility = {
  [ORDER_STATUS.AMP_REVIEW]: true,
  [ORDER_STATUS.UNDER_REVIEW]: true,
  [ORDER_STATUS.SUBMITTED]: true,
  [ORDER_STATUS.REVISION_REQUESTED]: true,
  default: false,
}

const NotificationContent = (props) => {
  const { messages } = props
  return (
    <div>
      {messages.map((message, index) =>
        <p key={index}>{message}</p>
      )}
    </div>
  )
}

const BadgeContent = (props) => {
  const { warrantyState } = props

  if (![REPURCHASE_WARRANTY_STATUSES.WARRANTY_DECLINED, REPURCHASE_WARRANTY_STATUSES.WARRANTY_GRANTED]
    .includes(warrantyState?.status)) {
    return <></>
  }

  if (warrantyState?.status === REPURCHASE_WARRANTY_STATUSES.WARRANTY_DECLINED) {
    return (
      <span className={classNames(styles.warrantyBadge, styles.warrantyBadgeAttention)}>
        Attention
      </span>
    )
  }

  if (warrantyState?.status === REPURCHASE_WARRANTY_STATUSES.WARRANTY_GRANTED) {
    const matching = Object.entries(warrantyState?.warranty_investors || {})
      .filter(([_, value]) => value.investor_status === REPURCHASE_WARRANTY_INVESTOR_STATUSES.INVESTOR_GRANTED)

    if (matching.length === 1) {
      return (
        <span className={classNames(styles.warrantyBadge, styles.warrantyBadgeSuccess)}>
          1 Investor
        </span>
      )
    }
  }

  return <></>
}

export const WarrantyStatusIcon = (props) => {
  const { status } = props

  switch (status) {
    case REPURCHASE_WARRANTY_STATUSES.WARRANTY_GRANTED:
      return <CheckCircleFilled />
    case REPURCHASE_WARRANTY_STATUSES.WARRANTY_DECLINED:
      return <WarningFilled />
    case REPURCHASE_WARRANTY_STATUSES.WARRANTY_SKIPPED:
      return <MinusCircleFilled />
    default:
      return <></>
  }
}


export const WarrantyStatusIconOutlined = (props) => {
  const { status } = props

  switch (status) {
    case REPURCHASE_WARRANTY_STATUSES.WARRANTY_GRANTED:
      return <CheckCircleOutlined />
    case REPURCHASE_WARRANTY_STATUSES.WARRANTY_DECLINED:
      return <WarningOutlined />
    case REPURCHASE_WARRANTY_STATUSES.WARRANTY_SKIPPED:
      return <MinusOutlined />
    default:
      return <></>
  }
}


const OrderWarrantyStatus = (props) => {
  const {
    orderData,
  } = props

  const { viewWarrantyReviews } = useFlags()
  const warrantyState = orderData?.repurchase_warranty

  const orderConfigVisibility = viewWarrantyReviews &&
      (orderData?.review_engine?.repurchase_warranty_enabled)
  const orderStateVisibility = orderStatusVisibility[orderData?.statusKey] ?? orderStatusVisibility.default
  const warrantyStateVisibility =
      repurchaseWarrantyStatusToVisibility[warrantyState?.status] ?? repurchaseWarrantyStatusToVisibility.default
  const warrantyStateColor =
      repurchaseWarrantyStatusToTag[warrantyState?.status] || repurchaseWarrantyStatusToTag.default

  const visibilityChecks = [orderConfigVisibility, orderStateVisibility, warrantyStateVisibility]
  const showRepurchaseWarranty = visibilityChecks.every(Boolean)

  const messages = warrantyState?.messages || []
  const popoverTrigger = messages.length > 0 ? ['click'] : []

  if (!showRepurchaseWarranty) {
    return <></>
  }

  const cursor = popoverTrigger.length ? 'pointer' : 'default'

  return (
    <>
      <div className="d-flex ml-4" data-test-id="warranty-badge">
        <Badge count={<BadgeContent warrantyState={warrantyState} />}>
          <Popover content={<NotificationContent messages={messages} />} placement="bottom" trigger={popoverTrigger}>
            <Tag className='h5' style={{ cursor, padding: '0.5rem 1rem', margin: '0' }} color={warrantyStateColor}><WarrantyStatusIcon status={warrantyState?.status} /> Reggora Warranty</Tag>
          </Popover>
        </Badge>

      </div>
    </>
  )
}

export default OrderWarrantyStatus
