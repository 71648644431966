// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".resultRuleset_3488b0 {\n  min-width: 150px;\n}", "",{"version":3,"sources":["webpack://src/app/pages/orders/SubmissionsTab/ReggoraReview/ResultRuleset/ResultRuleset.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB","sourcesContent":[".resultRuleset {\n  min-width: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resultRuleset": "resultRuleset_3488b0"
};
export default ___CSS_LOADER_EXPORT___;
