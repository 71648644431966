// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".warrantyBadge_20dd36 {\n  position: relative;\n  top: -1.5rem;\n  right: 1rem;\n  background-color: #fff;\n  border-radius: 1rem;\n  border: 1px solid #000;\n  padding: 0.25rem 0.25rem;\n}\n\n.warrantyBadgeAttention_718926 {\n  background-color: #fa8c16;\n  border-color: #fa8c16;\n  color: #fff;\n}\n\n.warrantyBadgeSuccess_c5122c {\n  background-color: #87d068;\n  border-color: #87d068;\n  color: #fff;\n}", "",{"version":3,"sources":["webpack://src/app/pages/orders/Warranty/OrderWarrantyStatus.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,sBAAsB;EACtB,mBAAmB;EACnB,sBAAsB;EACtB,wBAAwB;AAC1B;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,WAAW;AACb","sourcesContent":[".warrantyBadge {\n  position: relative;\n  top: -1.5rem;\n  right: 1rem;\n  background-color: #fff;\n  border-radius: 1rem;\n  border: 1px solid #000;\n  padding: 0.25rem 0.25rem;\n}\n\n.warrantyBadgeAttention {\n  background-color: #fa8c16;\n  border-color: #fa8c16;\n  color: #fff;\n}\n\n.warrantyBadgeSuccess {\n  background-color: #87d068;\n  border-color: #87d068;\n  color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warrantyBadge": "warrantyBadge_20dd36",
	"warrantyBadgeAttention": "warrantyBadgeAttention_718926",
	"warrantyBadgeSuccess": "warrantyBadgeSuccess_c5122c"
};
export default ___CSS_LOADER_EXPORT___;
